// valid email
const isEmail = (email: string) => {
  // eslint-disable-next-line max-len
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
}

// max 25 chars + only letters + accents or ñ
const isName = (name: string) => {
  return /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]{1,25}$/.test(name);
}

// value is not only white spaces
const isNotOnlyWhitespace = (value: string) => {
  return value.replace(/\s/g, '').length;
}

// passwords match
const passwordsMatch = (password: string, repeatPassword: string) => {
  return (password === repeatPassword);
}

// password min 8 chars + one mayus + one number + one special char
const isPassword = (password: string) => {
  // eslint-disable-next-line max-len
  return /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%_*?&])[A-Za-z\d@$!%_*?&]{8,30}$/.test(password);
}

export {
  isEmail,
  isName,
  isNotOnlyWhitespace,
  passwordsMatch,
  isPassword
};

