/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import Title from '../../components/Shared/Title';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import en from 'date-fns/locale/es';
import { useSelector, useDispatch } from 'react-redux';
import { selectDarkMode } from '../../redux/DarkMode/DarkModeSlice';
import { fetchAuth, selectAuth } from '../../redux/User/AuthSlice';
import useStyles from './UseStyles';
import { styles } from '../../config/styles';
import '../../styles/components/NewHabit.css';
import Spinner from '../Shared/Spinner';

const NewHabit = () => {
  const dispatch = useDispatch();

  const darkMode = useSelector(selectDarkMode);
  const { loading } = useSelector(selectAuth);
  const classes = useStyles(darkMode)();

  registerLocale('en', en);
  const [today] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [checkedB, setCheckedB] = useState(true);

  useEffect(() => {
    dispatch(fetchAuth());
  }, []);

  const handleChangeNotifications = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedB(!checkedB);
  };

  const PinkCheckbox = withStyles({
    root: {
      color: styles.colorPinkMain,
      '&$checked': {
        color: styles.colorPinkMain,
      },
      '&:hover': {
        backgroundColor: 'unset'
      },
      paddingLeft: '0px',
      paddingRight: '4px',
      paddingTop: '0px',
      marginLeft: '-3px'
    },
    checked: {}
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

  return (
    <React.Fragment>
      {(loading) ? (
        <Spinner />
      ) : (
        <div className="col-lg-12">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <Title title={'New habit'} />
            </div>
          </div>
          <form style={{ width: '100%' }}>
            <div className="row">
              <InputLabel className={classes.inputLabel} shrink>Name</InputLabel>
              <TextField
                id="name"
                InputProps={{classes: {root: classes.root, underline: classes.underline, input: classes.input}}}
                variant="filled"
                size="small"
              />
              <small className={classes.errorMessage}></small>
            </div>
            <div className="row">
              <InputLabel className={classes.inputLabel} shrink>Description</InputLabel>
              <TextField
                id="name"
                InputProps={{classes: {root: classes.root, underline: classes.underline, input: classes.input}}}
                variant="filled"
                multiline
              />
              <small className={classes.errorMessage}></small>
            </div>
            <div className="row">
              <div className={(darkMode === 'true') ?
                'col-lg-6 col-xs-12 col-date-left col-date-dark' :
                'col-lg-6 col-xs-12 col-date-left col-date-normal'}
              >
                <InputLabel className={classes.inputLabel} shrink>Start date</InputLabel>
                <DatePicker
                  className={(darkMode === 'true') ? 'datepicker-dark' : 'datepicker-normal'}
                  selected={startDate}
                  locale="en"
                  dateFormat={'dd-MM-yyyy'}
                  minDate={today}
                  onChange={(date : Date) => setStartDate(date)}
                />
                <small className={classes.errorMessage}>&nbsp;</small>
              </div>
              <div className={(darkMode === 'true') ?
                'col-lg-6 col-xs-12 col-date-right col-date-dark' :
                'col-lg-6 col-xs-12 col-date-right col-date-normal'}
              >
                <InputLabel className={classes.inputLabel} shrink>End date</InputLabel>
                <DatePicker
                  className={(darkMode === 'true') ? 'datepicker-dark' : 'datepicker-normal'}
                  selected={endDate}
                  locale="en"
                  dateFormat={'dd-MM-yyyy'}
                  minDate={today}
                  onChange={(date : Date) => setEndDate(date)}
                />
                <small className={classes.errorMessage}>&nbsp;</small>
              </div>
            </div>
            <div className="row">
              <InputLabel className={classes.inputLabel} shrink>Classification</InputLabel>
              <div className="col-4 px-0">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<ThumbUpIcon />}
                  style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px',
                    borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px' }}
                >
                  Good
                </Button>
              </div>
              <div className="col-4 px-0">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<ThumbDownIcon />}
                  style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px',
                    borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
                >
                  Bad
                </Button>
              </div>
              <div className="col-4 px-0">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<ThumbsUpDownIcon />}
                  style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px',
                    borderTopRightRadius: '6px', borderBottomRightRadius: '6px' }}
                >
                  Other
                </Button>
              </div>
              <small className={classes.errorMessage}>&nbsp;</small>
            </div>
            <div className="row">
              <InputLabel className={classes.inputLabel} shrink>Set days</InputLabel>
              <div className="col-3 px-0">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonSmallFont}
                  style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px',
                    borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px' }}
                >
                  Every day
                </Button>
              </div>
              <div className="col-3 px-0">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonSmallFont}
                  style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px',
                    borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
                >
                  Every two days
                </Button>
              </div>
              <div className="col-3 px-0">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonSmallFont}
                  style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px',
                    borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
                >
                  Weekly
                </Button>
              </div>
              <div className="col-3 px-0">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonSmallFont}
                  style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px',
                    borderTopRightRadius: '6px', borderBottomRightRadius: '6px' }}
                >
                  Monthly
                </Button>
              </div>
              <small className={classes.errorMessage}>&nbsp;</small>
            </div>
            <div className="row">
              <InputLabel className={classes.inputLabel} shrink>Set time</InputLabel>
              <div className="col-6 px-0">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px',
                    borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px' }}
                >
                  All day
                </Button>
              </div>
              <div className="col-6 px-0">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px',
                    borderTopRightRadius: '6px', borderBottomRightRadius: '6px' }}
                >
                  Times per day
                </Button>
              </div>
              <small className={classes.errorMessage}>&nbsp;</small>
            </div>
            <div className="row">
              <InputLabel className={classes.inputLabel} shrink>Notifications</InputLabel>
              <FormControlLabel
                classes={{ label: classes.checkboxNotificationLabel }}
                control={<PinkCheckbox checked={checkedB} onChange={handleChangeNotifications} name="checkedB" />}
                label="Send notifications"
              />
            </div>
            <div className="row d-flex justify-content-center mt-3">
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonCreate}
              >
                Create new habit
              </Button>
            </div>
          </form>
        </div>
      )
      }
    </React.Fragment>
  )
};

export default NewHabit;
