import { createSlice } from '@reduxjs/toolkit';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import axios from '../../services';

const initialState = {
  loading: false,
  auth: {},
  error: {}
};

export const LoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    init: (state) => {
      state.loading = true
    },
    authorized: (state) => {
      state.loading = false
      state.auth = { success: true }
      state.error = {}
    },
    unauthorized: (state) => {
      state.loading = false
      state.auth = { success: false, message: 'Invalid email or password' }
      state.error = {}
    },
    failure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    clean: (state) => {
      state.loading = false
      state.error = {}
    }
  },
});

// eslint-disable-next-line max-len
export const { init, authorized, unauthorized, failure, clean } = LoginSlice.actions;
export const selectLogin = (state: any) => state.login;
export default LoginSlice.reducer;

export function fetchLogin(email: string, password: string) {
  return async (dispatch: Dispatch<any, any, any>) => {
    try {
      dispatch(init());
      const response = await axios.post('/signIn', {
        email, password
      });
      if ('success' in response && response.success === true) {
        dispatch(authorized());
      } else {
        dispatch(failure({ message: response.data }));
      }
    } catch (error) {
      dispatch(failure({ message: 'Error en API' }));
    }
  }
};

export function initClean() {
  return async (dispatch: Dispatch<any, any, any>) => dispatch((clean()))
}