import React, { useState } from 'react';
import SignIn from '../../components/Home/SignIn';
import SignUp from '../../components/Home/SignUp';
import ForgotPassword from '../../components/Home/ForgotPassword';
import Header from '../../components/HomeMobile/Header';
import MoreInfo from '../../components/HomeMobile/MoreInfo';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { withOrientationChange } from 'react-device-detect';
import InfoMobile from '../../components/HomeMobile/InfoMobile';

const HomeMobilePage = (orientation: any) => {
  const { isLandscape } = orientation;
  const [sign, setSign] = useState('signIn');
  const [moreInfo, setMoreInfo] = useState(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      page: {
        display: 'flex',
        flexFlow: 'column',
        height: 'calc(100vh - 58px)'
      },
      moreInfoPage: {
        display: 'flex',
        alignItems: 'center',
        height: 'calc(100% - 58px)',
        backgroundColor: '#ff356a'
      }
    }),
  );

  const classes = useStyles();

  return (
    <div className={moreInfo ?
      'vh-100' :'vh-100 infoContainerWhite'
    }>
      <Header
        sign={sign}
        setSign={setSign}
        moreInfo={moreInfo}
        setMoreInfo={setMoreInfo}
      />
      <div className={moreInfo ? classes.moreInfoPage : classes.page}>
        {!moreInfo &&
          <div
            className="row justify-content-center gx-0"
            style={{ flex: '1 1 auto' }}
          >
            {sign === 'signIn' &&
              <SignIn setSign={setSign} />
            }
            {sign === 'signUp' &&
              <SignUp />
            }
            {sign === 'forgotPassword' &&
              <ForgotPassword />
            }
          </div>
        }
        {sign === 'signIn' && !moreInfo &&
          <MoreInfo setMoreInfo={setMoreInfo} />
        }
        {moreInfo &&
          <InfoMobile setMoreInfo={setMoreInfo} isLandscape={isLandscape}/>
        }
      </div>
    </div>
  )
}

export default withOrientationChange(HomeMobilePage);
