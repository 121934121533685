/* eslint-disable max-len */
import React, { FC, useState, MouseEvent, ChangeEvent } from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Divider from '../../components/Shared/Divider';
import LogoGoogle from '../../images/google-brands.svg';
import LogoFacebook from '../../images/facebook-f-brands.svg';
import useStyles from './UseStyles';
import validateSignIn from '../../utils/validations/validateSignIn';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogin, selectLogin, initClean } from '../../redux/User/LoginSlice';
import { isTablet, isMobileOnly } from 'react-device-detect';

interface Props {
  openInfo?: boolean,
  setSign: (value: string) => void
}

const SignIn: FC<Props> = ({ openInfo, setSign }: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const dispatch = useDispatch();
  const { loading, error } = useSelector(selectLogin);

  const [state, setState] = useState({
    errors: {
      email: '',
      password: ''
    },
    email: '',
    password: ''
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSignIn = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(initClean());
    // eslint-disable-next-line no-unused-vars
    const { errors, ...noErrors } = state;
    const results = validateSignIn(noErrors);
    setState((prevState) => ({ ...prevState, errors: results }));
    if (Object.entries(results).length === 0) {
      dispatch(fetchLogin(state.email, state.password));
    }
  };

  const classes = useStyles()();

  return (
    <div
      className={isMobileOnly ? 'col-9 align-items-center px-0 my-5' : 'col-7 align-items-center'}
      style={{
        display: openInfo ? 'none' : 'flex',
        animation: 'fadeIn 0.3s'
      }}
    >
      <div className="row justify-content-center">
        <div className={isTablet ? 'col-lg-10' : 'col-lg-6 col-xs-12'}>
          <div className="row justify-content-center">
            <Typography className={classes.formTitle} variant="h2">
              Welcome back!
            </Typography>
            <Typography className={classes.description} variant="body2">
              We are glad you are back. Log in with your email and password.
            </Typography>
            <IconButton aria-label="google" className={classes.iconButtonSocial}>
              <img src={LogoGoogle} className={classes.iconGoogle} alt="logoGoogle"/>
            </IconButton>
            <IconButton aria-label="facebook" className={classes.iconButtonSocial}>
              <img src={LogoFacebook} className={classes.iconFacebook} alt="logoFacebook"/>
            </IconButton>
            <Divider>Or</Divider>
            <div className="row mt-3 justify-content-center">
              <form>
                <div className="row">
                  <TextField
                    id="email"
                    name="email"
                    InputProps={{classes: {root: classes.inputRoot, underline: classes.underline, input: classes.input}}}
                    variant="filled"
                    placeholder="Email"
                    onChange={handleChange}
                  />
                  <Typography className={classes.errorMessage} variant="body2">
                    {state.errors.email}
                  </Typography>
                </div>
                <div className="row">
                  <TextField
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        underline: classes.underline,
                        input: classes.input
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            className={classes.seePasswordIcon}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    variant="filled"
                    placeholder="Password"
                    onChange={handleChange}
                  />
                  <Typography className={classes.errorMessage} variant="body2">
                    {state.errors.password || error.message}
                  </Typography>
                </div>
                <div className="row mt-1 justify-content-center">
                  <Button
                    className={classes.buttonEnter}
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={(e) => handleSignIn(e)}
                  >
                    <div className={classes.buttonText}>Sign in</div>
                    {loading &&
                      <CircularProgress className={classes.circularProgress} size={20} />
                    }
                  </Button>
                </div>
                <div className="row mt-2 justify-content-center">
                  <Link
                    className={classes.forgotPassword}
                    component="button"
                    variant="body2"
                    onClick={() => setSign('forgotPassword')}
                  >
                    Forgot password?
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default SignIn;