import React from 'react';
import SpinnerSvg from '../../images/spinner.svg';

const Spinner = () => {
  return (
    <div className="flex items-center h-screen justify-center w-full">
      <img className="spinner-top" src={SpinnerSvg} alt="spinner" />
    </div>
  )
};

export default Spinner;