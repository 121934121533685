import { configureStore } from '@reduxjs/toolkit';
import DarkModeReducer from './DarkMode/DarkModeSlice';
import LoginSlice from './User/LoginSlice';
import AuthSlice from './User/AuthSlice';
import RegisterSlice from './User/RegisterSlice';
import ForgotPasswordSlice from './User/ForgotPasswordSlice';

export const Store = configureStore({
  reducer: {
    darkMode: DarkModeReducer,
    login: LoginSlice,
    auth: AuthSlice,
    register: RegisterSlice,
    forgotPassword: ForgotPasswordSlice
  },
});