import { createSlice } from '@reduxjs/toolkit';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import axios from '../../services';

const initialState = {
  loading: false,
  forgotPassword: {},
  error: {}
};

export const ForgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    init: (state) => {
      state.loading = true
    },
    emailSent: (state) => {
      state.loading = false
      state.forgotPassword = { success: true }
      state.error = {}
    },
    failure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    clean: (state) => {
      state.loading = false
      state.error = {}
    }
  },
});

export const { init, emailSent, failure, clean } = ForgotPasswordSlice.actions;
export const selectForgotPassword = (state: any) => state.forgotPassword;
export default ForgotPasswordSlice.reducer;

export function fetchForgotPassword(email: string) {
  return async (dispatch: Dispatch<any, any, any>) => {
    try {
      dispatch(init());
      const response = await axios.post('/forgotPassword', {
        email
      });
      if ('success' in response && response.success === true) {
        dispatch(emailSent());
      } else {
        dispatch(failure({ message: response.data }));
      }
    } catch (error) {
      dispatch(failure({ message: 'Error en API' }));
    }
  }
}

export function initClean() {
  return async (dispatch: Dispatch<any, any, any>) => dispatch((clean()))
}