/* eslint-disable max-len */
import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { selectDarkMode } from '../../redux/DarkMode/DarkModeSlice';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { styles } from '../../config/styles';

interface Props {
  title: string
}

const Title: FC<Props> = ({ title }: Props) => {
  const darkMode = useSelector(selectDarkMode);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      typography: {
        fontSize: '28px',
        marginBottom: '16px',
        color: (darkMode === 'true') ? styles.colorDarkTitleText : styles.colorTitleText
      }
    }),
  );
  const classes = useStyles();

  return (
    <Typography className={classes.typography} variant="h5" gutterBottom>
      {title}
    </Typography>
  )
};

export default Title;