/* eslint-disable quotes */
/* eslint-disable max-len */
import React, { FC, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import useStyles from './UseStyles';
import MoreInfo1 from '../../images/more-info-1.png';
import MoreInfo2 from '../../images/more-info-2-2.png';
import MoreInfo3 from '../../images/more-info-3.png';

interface Props {
  setMoreInfo: (value: boolean) => void,
  isLandscape: boolean,
}

const InfoMobile: FC<Props> = ({ setMoreInfo, isLandscape }: Props) => {
  const [infoPageNumber, setInfoPageNumber] = useState(1);
  const infoArray = [
    {
      image: MoreInfo1,
      title: 'How does Atomic Habits work?',
      description: 'Atomic Habits was incubated following the principles of the aclaimed book by James Clear. You’ll use the same methods that elite athletes and successful entrepreneurs use to rise to the top of their game. Now you have the knowledge and a structured good-habits lifestyle, it’s your turn.'
    },
    {
      image: MoreInfo2,
      title: 'Find your ultimate daily routine and make it stick',
      description: 'Your habits sculpt the person you will become — choose them wisely. Get a boost in 2 minutes. On demand series available all day, all night. Morning, afternoon, evening routines guide you through every day. Whenever you want, book a session with a real live coach to inspire an inner shift.'
    },
    {
      image: MoreInfo3,
      title: "The science we're using behind this App",
      description: 'Atomic Habits was designed with a simple goal in mind: to fuel the world with the power of behavioral science. Our team of behavioral economists, psychologists, and data scientists are constantly improving Atomic Habits and finding new ways to share the benefits with more people.'
    },
  ];

  const handleMoreInfo = () => {
    if (infoPageNumber < 3) {
      setInfoPageNumber(infoPageNumber+1);
    } else {
      setMoreInfo(false);
    }
  };

  const classes = useStyles();

  return (
    <Fade in={true} timeout={500}>
      <div className={classes.infoContent}>
        <div className="row mx-5 justify-content-center">
          <img
            className={isLandscape ? 'w-25' : 'w-75'}
            src={infoArray[infoPageNumber-1].image}
            alt="info-img"
          />
        </div>
        <div className={isLandscape ? 'row pt-3 mx-2' : 'row pt-5 mx-4'}>
          <Typography
            className={classes.infoMobileTitle}
            variant="h5"
            component="h1"
            gutterBottom
          >
            {infoArray[infoPageNumber-1].title}
          </Typography>
        </div>
        <div className="row pt-3 mx-2">
          <Typography
            className={classes.infoMobileDescription}
            variant="h6"
          >
            {infoArray[infoPageNumber-1].description}
          </Typography>
        </div>
        <div className="row mx-5 justify-content-center">
          <Button
            className={classes.buttonPink}
            variant="contained"
            color="primary"
            onClick={() => handleMoreInfo()}
          >
            {infoPageNumber < 3 ? 'MORE INFORMATION' : 'RETURN TO LOGIN'}
          </Button>
        </div>
      </div>
    </Fade>
  )
};

export default InfoMobile;