/* eslint-disable max-len */
import React, { FC, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreInfo1 from '../../images/more-info-1.png';
import { isTablet } from 'react-device-detect';
import useStyles from './UseStyles';
import '../../styles/components/Info.css';

interface Props {
  setInfoPageNumber: (value: number) => void,
}

const InfoFirst: FC<Props> = ({ setInfoPageNumber }: Props) => {
  const [actualPage, setActualPage] = useState(true);

  const handleChangePage = () => {
    setActualPage(false);
    setTimeout(() => {
      setInfoPageNumber(2);
    }, 1000);
  };

  const classes = useStyles()();

  return (
    <div
      className={actualPage ?
        clsx(classes.centerVerticalInfoFirst, classes.animationInDown) :
        clsx(classes.centerVerticalInfoFirst, classes.animationOutDown)
      }
    >
      <div className={isTablet ? 'row mx-5 fixTabletInfoMargin' : 'row mx-5 fixDesktopInfoMargin'}>
        <div className="col-lg-6 fixCol6Center">
          <Typography
            className={clsx(classes.infoTitle, classes.paddingLeftInfo)}
            variant="h1"
            component="h2"
            gutterBottom
          >
            How does this App work?
          </Typography>
          <Typography
            className={clsx(classes.infoDescription, classes.paddingLeftInfo)}
            variant="h5"
          >
            Atomic Habits was incubated following the principles of the aclaimed book by James Clear. Using a science-backed approach to habit-building, you’ll learn how to turn small daily tasks into profound and lasting change. You’ll use the same methods that elite athletes and successful entrepreneurs use to rise to the top of their game. Now it’s your turn.
          </Typography>
          <Button
            className={clsx(classes.marginLeftInfo, classes.buttonInfoExpandMore)}
            variant="contained"
            color="primary"
            onClick={handleChangePage}
            startIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}
          >
            MORE INFORMATION
          </Button>
        </div>
        <div className="col-lg-6">
          <img
            className={isTablet ?
              'fixTabletInfoImage' :
              clsx(classes.infoImage, classes.paddingRightInfo)}
            src={MoreInfo1}
            alt="info-img"
          />
        </div>
      </div>
    </div>
  )
};

export default InfoFirst;