import { isEmail } from '../validations';

interface values {
  email?: string;
  password?: string;
}

const validateSignIn = (values: values) => {
  const errors: any = {};
  if (!values.password) {
    errors.password = 'Please enter a password';
  }
  if (values.email && !isEmail(values.email)) {
    errors.email = 'Please enter a valid email';
  }
  if (!values.email) {
    errors.email = 'Please enter an email';
  }
  return errors;
};

export default validateSignIn;
