/* eslint-disable max-len */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import EventIcon from '@material-ui/icons/Event';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddIcon from '@material-ui/icons/Add';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { BrowserView, isMobile, isTablet } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { changeDarkMode, selectDarkMode } from '../../redux/DarkMode/DarkModeSlice';
import Logo from '../../images/logo-white-svg.svg';
import LogoDarkMode from '../../images/logo-svg.svg';
import { config } from '../../config/index';
import useStyles from './UseStyles';
import '../../styles/components/Header.css';

const Header = () => {
  /* Dark mode Redux */
  const darkMode = useSelector(selectDarkMode);
  const dispatch = useDispatch();
  const handleDarkmode = () => {
    dispatch(changeDarkMode());
    localStorage.setItem('darks', (darkMode === 'true') ? 'false' : 'true');
  };

  /* Material UI classes */
  const classes = useStyles(darkMode, isMobile, isTablet)();

  /* Mobile bottom menu items */
  interface menuMobileItemsProps {
    name: string;
    href: string;
    icon: React.ReactElement<SvgIconProps>;
  }
  const [bottom, setBottom] = useState(false);
  const [mobileSelectedMenu, setMobileSelectedMenu] = useState('null');
  const [menuMobileItems, setMenuMobileItems] = useState<menuMobileItemsProps[]>([]);
  const [bottomNavigationValue, setBottomNavigationValue] = useState(-1);
  type Anchor = 'top' | 'left' | 'bottom' | 'right';

  /* Ṕrofile menu */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /* Habits menu */
  const [anchorElButtonHabits, setAnchorElButtonHabits] = useState<null | HTMLElement>(null);
  const handleClickButtonHabits = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElButtonHabits(event.currentTarget);
  };
  const handleCloseButtonHabits = () => {
    setAnchorElButtonHabits(null);
  };

  /* Notifications menu */
  const [anchorElButtonNotifications, setAnchorElButtonNotifications] = useState<null | HTMLElement>(null);
  const handleClickButtonNotifications = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElButtonNotifications(event.currentTarget);
  };
  const handleCloseButtonNotifications = () => {
    setAnchorElButtonNotifications(null);
  };

  /* Profile menu element */
  const menuId = 'menu-account';
  const renderMenu = (
    <Menu
      classes={{ paper: classes.menuPaper }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClick={handleMenuClose}
    >
      {isMobile &&
        <MenuItem onClick={() => {
          handleMenuClose();
          handleDarkmode();
        }}
        classes={{ root: classes.menuItemRoot, gutters: classes.menuItemGutters }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <Brightness2Icon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Dark mode</Typography>
        </MenuItem>
      }
      {isMobile &&
        <MenuItem onClick={handleMenuClose} classes={{ root: classes.menuItemRoot, gutters: classes.menuItemGutters }}>
          <ListItemIcon className={classes.listItemIcon}>
            <MailIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Messages</Typography>
        </MenuItem>
      }
      {isMobile &&
        <MenuItem onClick={handleMenuClose} classes={{ root: classes.menuItemRoot, gutters: classes.menuItemGutters }}>
          <ListItemIcon className={classes.listItemIcon}>
            <NotificationsIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Notifications</Typography>
        </MenuItem>
      }
      <MenuItem onClick={handleMenuClose} classes={{ root: classes.menuItemRoot, gutters: classes.menuItemGutters }}>
        <ListItemIcon className={classes.listItemIcon}>
          <VpnKeyIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Change password</Typography>
      </MenuItem>
      <MenuItem onClick={handleMenuClose} classes={{ root: classes.menuItemRoot, gutters: classes.menuItemGutters }}>
        <ListItemIcon className={classes.listItemIcon}>
          <SettingsIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Settings</Typography>
      </MenuItem>
      <MenuItem onClick={handleMenuClose} classes={{ root: classes.menuItemRoot, gutters: classes.menuItemGutters }}>
        <ListItemIcon className={classes.listItemIcon}>
          <ExitToAppIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Sign out</Typography>
      </MenuItem>
    </Menu>
  );

  /* Toggle bottom menu */
  const toggleDrawer = (anchor: Anchor, open: boolean, type: string) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setMobileSelectedMenu(type);
    setMenuMobileItems(type === 'habits' ?
      [{'name': 'New habit', 'href': 'new-habit', 'icon': <AddIcon className={classes.menuMobileItemIcon} />},
        {'name': 'My habits', 'href': 'my-habits', 'icon': <DirectionsRunIcon className={classes.menuMobileItemIcon} />}] :
      [{'name': 'New notification', 'href': 'new-notification', 'icon': <AddIcon className={classes.menuMobileItemIcon} />},
        {'name': 'My notifications', 'href': 'my-notifications', 'icon': <NotificationsIcon className={classes.menuMobileItemIcon} />}]);
    console.log(bottom, open, type);
    if (bottom && !open && type === 'null') {
      console.log(type, mobileSelectedMenu);
      setBottom(false);
    }
    if (!(mobileSelectedMenu !== type && bottom)) {
      setBottom(open);
    }
    if (!open && type === mobileSelectedMenu) {
      setBottomNavigationValue(-1);
    }
  };

  /* Popover */
  const [anchorElPopover, setAnchorElPopover] = useState<HTMLElement | null>(null);
  const [popoverText, setPopoverText] = useState('');
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, text: string) => {
    setPopoverText(text);
    setAnchorElPopover(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorElPopover(null);
  };
  const open = Boolean(anchorElPopover);

  /* List of items of each mobile menu element (habits and notifications) */
  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false, 'null')}
      onKeyDown={toggleDrawer(anchor, false, 'null')}
    >
      <List>
        {bottom &&
          menuMobileItems.map((item, index) => (
            <ListItem button key={index} component={Link} to={'/'+item.href}>
              {item.icon}
              <ListItemText primary={item.name} />
            </ListItem>
          ))
        }
      </List>
    </div>
  );

  return (
    <div className={classes.grow}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar className={classes.toolbar}>
          {isMobile &&
            <React.Fragment key={'bottom'}>
              <SwipeableDrawer
                anchor={'bottom'}
                open={bottom}
                onClose={toggleDrawer('bottom', false, mobileSelectedMenu)}
                onOpen={toggleDrawer('bottom', true, mobileSelectedMenu)}
              >
                {list('bottom')}
              </SwipeableDrawer>
            </React.Fragment>
          }
          <Link id="homeLogoTitle" onClick={toggleDrawer('bottom', false, 'null')} className={classes.home} to="/">
            <img src={(darkMode === 'true') ? LogoDarkMode : Logo} className={classes.logo} alt="logo" />
            <Typography className={classes.title} variant="h6" noWrap>
              { config.appName }
            </Typography>
          </Link>
          <div className={classes.menuDiv}>
            <BrowserView>
              <Button className={classes.menuButton} aria-controls="menu-habits"
                aria-haspopup="true" onClick={handleClickButtonHabits}>
                Habits
              </Button>
              <Menu
                id="menu-habits"
                classes={{ paper: classes.menuPaper }}
                anchorEl={anchorElButtonHabits}
                keepMounted
                open={Boolean(anchorElButtonHabits)}
                onClose={handleCloseButtonHabits}
              >
                <MenuItem
                  classes={{ root: classes.menuItemRoot, gutters: classes.menuItemGutters }}
                  onClick={handleCloseButtonHabits}
                  component={Link}
                  to={'/new-habit'}
                >
                  New habit
                </MenuItem>
                <MenuItem
                  classes={{ root: classes.menuItemRoot, gutters: classes.menuItemGutters }}
                  onClick={handleCloseButtonHabits}
                  component={Link}
                  to={'/my-habits'}
                >
                  My habits
                </MenuItem>
              </Menu>
              <Button className={classes.menuButton} aria-controls="menu-notifications"
                aria-haspopup="true" onClick={handleClickButtonNotifications}>
                Notifications
              </Button>
              <Menu
                id="menu-notifications"
                classes={{ paper: classes.menuPaper }}
                anchorEl={anchorElButtonNotifications}
                keepMounted
                open={Boolean(anchorElButtonNotifications)}
                onClose={handleCloseButtonNotifications}
              >
                <MenuItem classes={{ root: classes.menuItemRoot, gutters: classes.menuItemGutters }} onClick={handleCloseButtonNotifications}>New notification</MenuItem>
                <MenuItem classes={{ root: classes.menuItemRoot, gutters: classes.menuItemGutters }} onClick={handleCloseButtonNotifications}>My notifications</MenuItem>
              </Menu>
              <Button className={classes.menuButton} aria-controls="menu-calendar"
                aria-haspopup="true">
                Calendar
              </Button>
              <Button className={classes.menuButton} aria-controls="menu-achievements"
                aria-haspopup="true">
                Achievements
              </Button>
            </BrowserView>
          </div>
          <div className={classes.desktop}>
            <IconButton
              onClick={handleDarkmode}
              onMouseEnter={(e) => handlePopoverOpen(e, 'Dark mode')}
              onMouseLeave={handlePopoverClose}
              aria-label="show 4 new mails"
              color="inherit"
            >
              <Badge color="secondary">
                <Brightness2Icon className={classes.menuIcon} />
              </Badge>
            </IconButton>
            <IconButton
              aria-label="show 4 new mails"
              onMouseEnter={(e) => handlePopoverOpen(e, 'You have no unread messages')}
              onMouseLeave={handlePopoverClose}
              color="inherit"
            >
              <Badge classes={{ badge: classes.badge }} badgeContent={4} max={99} color="secondary">
                <MailIcon className={classes.menuIcon} />
              </Badge>
            </IconButton>
            <IconButton
              aria-label="show 17 new notifications"
              onMouseEnter={(e) => handlePopoverOpen(e, 'You have no unread notifications')}
              onMouseLeave={handlePopoverClose}
              color="inherit"
            >
              <Badge classes={{ badge: classes.badge }} badgeContent={17} max={99} color="secondary">
                <NotificationsIcon className={classes.menuIcon} />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              onMouseEnter={(e) => handlePopoverOpen(e, 'My account')}
              onMouseLeave={handlePopoverClose}
              color="inherit"
            >
              <AccountCircle className={classes.menuIcon} />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {isMobile &&
        <React.Fragment>
          <BottomNavigation
            value={bottomNavigationValue}
            onChange={(event, newValue) => {
              setBottomNavigationValue(newValue);
            }}
            showLabels
            className={classes.bottomNavigation}
          >
            <BottomNavigationAction
              classes={{ root: classes.buttonNavigationActionRoot, selected: classes.buttonNavigationActionSelected }}
              label="Habits" icon={<DirectionsRunIcon />} onClick={toggleDrawer('bottom', !bottom, 'habits')} />
            <BottomNavigationAction
              classes={{ root: classes.buttonNavigationActionRoot, selected: classes.buttonNavigationActionSelected }}
              label="Notifications" icon={<NotificationsIcon />} onClick={toggleDrawer('bottom', !bottom, 'notifications')} />
            <BottomNavigationAction
              classes={{ root: classes.buttonNavigationActionRoot, selected: classes.buttonNavigationActionSelected }}
              label="Calendar" icon={<EventIcon />} />
            <BottomNavigationAction
              classes={{ root: classes.buttonNavigationActionRoot, selected: classes.buttonNavigationActionSelected }}
              label="Achievements" icon={<CheckCircleIcon />} />
          </BottomNavigation>
        </React.Fragment>
      }
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.popoverPaper,
        }}
        open={open}
        anchorEl={anchorElPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{popoverText}</Typography>
      </Popover>
    </div>
  )
};

export default Header;