import React, { FC } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Logo from '../../images/logo-white-svg.svg';
import { config } from '../../config/index';
import useStyles from './UseStyles';

interface Props {
  sign: string,
  setSign: (value: string) => void,
  moreInfo: boolean,
  setMoreInfo: (value: boolean) => void,
}

const Header: FC<Props> = ({ sign, setSign, moreInfo, setMoreInfo }: Props) => {
  const classes = useStyles();

  const handleSignUp = () => {
    if (moreInfo) {
      setSign('signUp');
      setMoreInfo(false);
    } else {
      setSign('signUp');
    }
  };

  return (
    <AppBar className={classes.header} position="static">
      <Toolbar className={classes.toolbar}>
        <Link id="homeLogoTitle" className={classes.home} to="/">
          <img
            src={Logo}
            className={classes.logo}
            alt="logo"
          />
          <Typography className={classes.title} variant="h6" noWrap>
            { config.appName }
          </Typography>
        </Link>
        {sign === 'signIn' &&
          <Button
            className={classes.buttonPinkHeader}
            variant="contained"
            color="primary"
            onClick={() => handleSignUp()}
          >
            Sign up
          </Button>
        }
        {(sign === 'signUp' || sign === 'forgotPassword') &&
          <Button
            className={classes.buttonPinkHeader}
            variant="contained"
            color="primary"
            onClick={() => setSign('signIn')}
          >
            Sign in
          </Button>
        }
      </Toolbar>
    </AppBar>
  )
};

export default Header;