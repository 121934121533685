import React, { FC } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './UseStyles';

interface Props {
  setMoreInfo: (value: boolean) => void
}

const MoreInfo: FC<Props> = ({ setMoreInfo }: Props) => {
  const classes = useStyles();

  return (
    <div
      className={classes.expandIconWrapper}
      onClick={() => setMoreInfo(true)}
    >
      <IconButton
        className={classes.iconButtonExpand}
        color="primary"
        aria-label="more info"
        component="span"
      >
        <AddIcon className={classes.expandIcon} />
      </IconButton>
      <span className={classes.moreInfoSpan}>More information</span>
    </div>
  )
}

export default MoreInfo;
