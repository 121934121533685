import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { isMobileOnly, isTablet } from 'react-device-detect';
// import { styles } from '../../config/styles';

interface Props {
  children: React.ReactNode
}

const PublicLayout: FC<Props> = ({ children }: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      content: {
        height: '100%'
      },
    }),
  );

  const classes = useStyles();

  return (
    <div className="container-fluid px-0 h-100">
      <div className={classes.content}>
        {children}
      </div>
    </div>
  )
};

export default PublicLayout;
