export const styles = {
  colorPinkMain: '#ff356a',
  colorHoverPinkMain: '#dd1e51',
  colorHoverLightPinkMain: '#ff7096',
  colorSelectedPinkMain: '#ba123e',
  colorDarkMain: '#161b22',
  colorHoverDarkMain: '#05080c',
  colorPurpleMain: '#b5006d',
  colorWhiteText: '#fff',
  colorDarkText: '#ddd',
  colorHoverWhiteText: '#ffd6e1',
  colorHoverDarkText: '#aaa',
  colorPinkBackground: '#f4f8f7',
  colorDarkBackground: '#0d1117',
  colorWhiteWrapper: '#fff',
  colorDarkWrapper: '#161b22',
  colorTitleText: '#222',
  colorDarkTitleText: '#ddd',
  colorPinkTextfield: '#222',
  colorDarkPinkTextfield: '#ff7096',
  colorErrorMessage: '#ff356a',
  colorDarkErrorMessage: '#ffc0d0',
  colorBackgroundTextfield: '#e8e8e8',
  colorBackgroundHoverTextfield: '#eeeeee',
  colorDarkBackgroundTextfield: '#11151b',
  colorInfoImage: '#00000038',
  colorHoverInfoImage: '#0000005c',
  colorDescriptionText: '#666',
};