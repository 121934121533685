/* eslint-disable max-len */
import React, { FC, useEffect } from 'react';
import Header from '../Header/Header';
import { useSelector, useDispatch } from 'react-redux';
import { selectDarkMode } from '../../redux/DarkMode/DarkModeSlice';
import { selectAuth } from '../../redux/User/AuthSlice';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { styles } from '../../config/styles';
import '../../styles/components/PrivateLayout.css';

interface Props {
  children: React.ReactNode
}

const PrivateLayout: FC<Props> = ({ children }: Props) => {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const darkMode = useSelector(selectDarkMode);
  const { loading } = useSelector(selectAuth);

  useEffect(() => {
    // if (auth !== undefined && !auth) {
    //   window.location.replace('/');
    // }
  }, []);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      content: {
        flex: '1 1 auto',
        backgroundColor: (darkMode === 'true') ? styles.colorDarkBackground : styles.colorPinkBackground,
        height: '100%',
        justifyContent: 'center',
        display: 'flex',
      },
      wrapper: {
        paddingLeft: isMobileOnly ? '30px' : isTablet ? '50px' : '60px',
        paddingRight: isMobileOnly ? '30px' : isTablet ? '50px' : '60px',
        paddingTop: '35px',
        paddingBottom: '35px',
        backgroundColor: (darkMode === 'true') ? styles.colorDarkWrapper : styles.colorWhiteWrapper,
        marginTop: isMobileOnly ? '20px' : '45px',
        marginBottom: isMobileOnly ? '20px' : '45px',
        borderRadius: '10px',
        height: 'fit-content',
        width: isMobileOnly ? '90%' : isTablet ? '60%' : '40%',
      },
      wrapperSpinner: {
        backgroundColor: '#0000',
        margin: 0,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        height: 'fit-content',
        width: isMobileOnly ? '90%' : isTablet ? '60%' : '40%',
      }
    }),
  );

  const classes = useStyles();

  return (
    <div className="container-fluid px-0">
      <Header />
      <div className={classes.content}>
        <div className={loading ? classes.wrapperSpinner : classes.wrapper}>
          {children}
        </div>
      </div>
    </div>
  )
};

export default PrivateLayout;
