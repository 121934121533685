import React, { FC } from 'react'
import useStyles from './UseStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


interface Props {
  image: string,
  title: string,
  description: string,
}

const InfoCard: FC<Props> = ({ image, title, description }: Props) => {
  const classes = useStyles()();

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent className={classes.cardComponent}>
        <img className={classes.cardImage} width="50%" src={image} alt="card" />
        <Typography className={classes.cardTitle} variant="h5" component="h2">
          {title}
        </Typography>
        <Typography
          className={classes.cardDescription}
          variant="body2"
          component="p"
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default InfoCard;
