import { createSlice } from '@reduxjs/toolkit';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import axios from '../../services';

const initialState = {
  loading: false,
  register: {},
  error: {}
};

export const RegisterSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    init: (state) => {
      state.loading = true
    },
    registered: (state) => {
      state.loading = false
      state.register = { success: true }
      state.error = {}
    },
    failure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    clean: (state) => {
      state.loading = false
      state.error = {}
    }
  },
});

export const { init, registered, failure, clean } = RegisterSlice.actions;
export const selectRegister = (state: any) => state.register;
export default RegisterSlice.reducer;

export function fetchRegister(name: string, email: string, password: string) {
  return async (dispatch: Dispatch<any, any, any>) => {
    try {
      dispatch(init());
      const response = await axios.post('/signUp', {
        name, email, password
      });
      if ('success' in response && response.success === true) {
        dispatch(registered());
      } else {
        dispatch(failure({ message: response.data }));
      }
    } catch (error) {
      dispatch(failure({ message: 'Error en API' }));
    }
  }
}

export function initClean() {
  return async (dispatch: Dispatch<any, any, any>) => dispatch((clean()))
}