import React, { FC, useState } from 'react';
import InfoCard from './InfoCard';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import useStyles from './UseStyles';
import clsx from 'clsx';
import { isTablet } from 'react-device-detect';
import { texts } from '../../config/texts';
import MoreInfo1 from '../../images/more-info-2-1.png';
import MoreInfo2 from '../../images/more-info-2-2.png';
import MoreInfo3 from '../../images/more-info-2-3.png';
import MoreInfo4 from '../../images/more-info-2-4.png';

interface Props {
  setInfoPageNumber: (value: number) => void
}

const InfoSecond: FC<Props> = ({ setInfoPageNumber }: Props) => {
  const [actualPage, setActualPage] = useState(true);

  const handleChangePage = () => {
    setActualPage(false);
    setTimeout(() => {
      setInfoPageNumber(3);
    }, 1000);
  };

  const classes = useStyles()();

  return (
    <div
      className={actualPage ?
        clsx(classes.centerVerticalInfoSecond, classes.animationInDown) :
        clsx(classes.centerVerticalInfoSecond, classes.animationOutDown)
      }
    >
      <div
        className={isTablet ?
          'fixInfoColumnsMarginTablet' : 'row mx-5 fixInfoColumnsMargin'
        }
      >
        <Typography
          className={classes.infoSecondTitle}
          variant="h5"
          component="h2"
        >
          Find your ultimate daily routine and make it stick
        </Typography>
      </div>
      <div className="row mx-5 mt-4 fixInfoColumnsPadding">
        <div className="col-3">
          <InfoCard
            image={MoreInfo1}
            title="Create healthy habits"
            description={texts.infoDescriptionOne}
          />
        </div>
        <div className="col-3">
          <InfoCard
            image={MoreInfo2}
            title="Create structure in your life"
            description={texts.infoDescriptionTwo}
          />
        </div>
        <div className="col-3">
          <InfoCard
            image={MoreInfo3}
            title="Join a community"
            description={texts.infoDescriptionThree}
          />
        </div>
        <div className="col-3">
          <InfoCard
            image={MoreInfo4}
            title="Focus on deep work"
            description={texts.infoDescriptionFour}
          />
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <Button
          className={classes.buttonInfoExpandMore}
          variant="contained"
          color="primary"
          onClick={handleChangePage}
          startIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}
        >
          More information
        </Button>
      </div>
    </div>
  );
};

export default InfoSecond;
