/* eslint-disable max-len */
export const texts = {
  infoDescriptionOne:
    'Your habits sculpt the person you will become — choose them wisely. Get a boost in 2 minutes. On demand series available all day, all night.',
  infoDescriptionTwo:
    'Morning, afternoon, evening routines guide you through every day. Whenever you want, book a session with a real live coach to inspire an inner shift.',
  infoDescriptionThree:
    'Give and get support with other members just like you. Share your experience with your friends via Facebook, Instagram, Twitter, and WhatsApp.',
  infoDescriptionFour:
    'Learn how to tune out noise. Streamline your mind into a flow state. Achive your monthly and annual goals. Be the best version of yourself.',
};