import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './styles/components/App.css';
import HomePage from './pages/HomePage/HomePage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import NewHabitPage from './pages/NewHabitPage/NewHabitPage';
import PublicLayout from './components/Layout/PublicLayout';
import PrivateLayout from './components/Layout/PrivateLayout';
import { useSelector } from 'react-redux';
import { selectLogin } from './redux/User/LoginSlice';

function App() {
  const { auth } = useSelector(selectLogin);

  return (
    <React.Fragment>
      {auth !== undefined && !auth.success &&
        <PublicLayout>
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route render={()=> <Redirect to="/"/>} />
          </Switch>
        </PublicLayout>
      }
      {auth !== undefined && auth.success &&
        <PrivateLayout>
          <Switch>
            <Route path="/" exact component={DashboardPage} />
            <Route path="/new-habit" exact component={NewHabitPage} />
          </Switch>
        </PrivateLayout>
      }
    </React.Fragment>
  );
};

export default App;
