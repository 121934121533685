import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: (localStorage.getItem('darks') === 'true') ? 'true' : 'false'
}

export const DarkModeSlice = createSlice({
  name: 'darkMode',
  initialState,
  reducers: {
    changeDarkMode: (state) => {
      state.value = (state.value === 'true') ? 'false' : 'true';
    },
  },
});

export const { changeDarkMode } = DarkModeSlice.actions;
export const selectDarkMode = (state : any) => state.darkMode.value;
export default DarkModeSlice.reducer;