import React from 'react';
import NewHabit from '../../components/NewHabit/NewHabit';
import Grid from '@material-ui/core/Grid';

const NewHabitPage = () => {
  return (
    <Grid container justifyContent="center">
      <NewHabit />
    </Grid>
  )
}

export default NewHabitPage;
