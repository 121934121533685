/* eslint-disable max-len */
import React, { FC, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import clsx from 'clsx';
import Logo from '../../images/logo-white-svg.svg';
import Info from './Info';
import useStyles from './UseStyles';
import { config } from '../../config/index';

interface Props {
  infoType: string,
  openInfo: boolean
  setOpenInfo: (value: boolean) => void,
  sign: string,
  setSign: (value: string) => void
}

const Description: FC<Props> = ({ infoType, openInfo, setOpenInfo, sign, setSign }: Props) => {
  const [showDescription, setShowDescription] = useState(true);
  const classes = useStyles()();

  return (
    <div className={openInfo ? clsx(classes.colDescription, classes.colDescriptionOpen) :
      clsx(classes.colDescription, classes.colDescriptionClose)}>
      <Info
        setShowDescription={setShowDescription}
        showDescription={showDescription}
        infoType={infoType}
        openInfo={openInfo}
        setOpenInfo={setOpenInfo}
        setSign={setSign}
      />
      <div
        className="row justify-content-center px-5"
        style={{
          display: (openInfo || !showDescription) ? 'none' : 'flex',
          animation: 'fadeIn 0.3s'
        }}
      >
        <img className={classes.logo} src={Logo} alt="logo" />
        <Typography className={classes.title} variant="h1">
          { config.appName }
        </Typography>
        <Typography className={classes.descriptionLeft} variant="h6">
          Atomic Habits is an self-care coaching app that harnesses the power and wisdom of behavioral science to help you develop lasting healthy habits. You’ll learn how to create meaningful daily rituals and stack habits to create routines that guide you towards achieving all your goals.
        </Typography>
        <Button
          className={classes.buttonPink}
          variant="contained"
          color="primary"
          onClick={() => setSign(sign === 'signIn' ? 'signUp' : 'signIn')}
        >
          {sign === 'signIn' ? 'SIGN UP' : 'SIGN IN'}
        </Button>
        <Link
          className={classes.moreInfo}
          component="button"
          variant="body2"
          onClick={() => setOpenInfo(!openInfo)}
        >
          More information
        </Link>
      </div>
    </div>
  )
};

export default Description;
