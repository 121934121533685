/* eslint-disable max-len */
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { styles } from '../../config/styles';

const useStyles = (darkMode: string, isMobile: boolean, isTablet: boolean) => makeStyles((theme: Theme) =>
  createStyles({
    desktop: {
      display: isMobile ? 'none' : 'block'
    },
    appBar: {
      maxHeight: '58px',
      backgroundColor: (darkMode === 'true') ? styles.colorDarkMain : styles.colorPinkMain,
      boxShadow: 'none'
    },
    menuDiv: {
      margin: '0 auto'
    },
    toolbar: {
      maxHeight: '60px',
      minHeight: '60px',
      [theme.breakpoints.only('lg')]: { paddingLeft: '60px', paddingRight: '60px' },
      [theme.breakpoints.only('xl')]: { paddingLeft: '160px', paddingRight: '160px' },
    },
    menuButton: {
      color: (darkMode === 'true') ? styles.colorDarkText : styles.colorWhiteText,
      '&:hover': {
        color: styles.colorHoverWhiteText
      },
      fontSize: '16px',
      fontWeight: 600,
      textTransform: 'none',
      [theme.breakpoints.only('lg')]: { paddingLeft: '20px', paddingRight: '20px' },
      [theme.breakpoints.only('xl')]: { paddingLeft: '30px', paddingRight: '30px' },
    },
    menuItemRoot: {
      color: (darkMode === 'true') ? styles.colorDarkText : styles.colorWhiteText,
      backgroundColor: (darkMode === 'true') ? styles.colorDarkMain : styles.colorPinkMain,
      '&:hover': {
        backgroundColor: (darkMode === 'true') ? styles.colorHoverDarkMain : styles.colorHoverPinkMain,
      }
    },
    menuItemGutters: {
      color: (darkMode === 'true') ? styles.colorDarkText : styles.colorWhiteText,
      backgroundColor: (darkMode === 'true') ? styles.colorDarkMain : styles.colorPinkMain,
      '&:hover': {
        backgroundColor: (darkMode === 'true') ? styles.colorHoverDarkMain : styles.colorHoverPinkMain,
        color: styles.colorHoverWhiteText,
        '& $listItemIcon': {
          color: styles.colorHoverWhiteText,
        }
      },
    },
    menuPaper: {
      marginLeft: isTablet ? '24px' : 'unset',
      backgroundColor: (darkMode === 'true') ? styles.colorDarkMain : styles.colorPinkMain,
    },
    logo: {
      width: isMobile ? '30px' : '36px',
      height: isMobile ? '30px' : '36px'
    },
    home: {
      color: (darkMode === 'true') ? styles.colorDarkText : styles.colorWhiteText,
      '&:hover': {
        color: (darkMode === 'true') ? styles.colorDarkText : styles.colorWhiteText
      },
      display: 'flex',
      textDecoration: 'none',
      [theme.breakpoints.only('xs')]: { paddingLeft: '2%' },
    },
    menuIcon: {
      color: (darkMode === 'true') ? styles.colorDarkText : styles.colorWhiteText,
      '&:hover': {
        color: styles.colorHoverWhiteText
      }
    },
    listItemIcon: {
      minWidth: '33px',
      color: (darkMode === 'true') ? styles.colorDarkText : styles.colorWhiteText,
    },
    badge: {
      backgroundColor: styles.colorPurpleMain,
      color: styles.colorWhiteText
    },
    grow: {
      flexGrow: 1,
    },
    iconButton: {
      marginRight: theme.spacing(4),
    },
    title: {
      [theme.breakpoints.up('sm')]: {
        display: 'block',
        marginLeft: '8px',
        fontWeight: '600',
        fontSize: '1.35rem',
        fontFamily: 'Ubuntu, sans-serif'
      },
      [theme.breakpoints.only('xs')]: { paddingLeft: '6px' },
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: isMobile ? 'flex' : 'none'
    },
    bottomNavigation: {
      backgroundColor: (darkMode === 'true') ? styles.colorDarkMain : styles.colorPinkMain,
      width: '100%',
      position: 'fixed',
      bottom: 0
    },
    list: {
      width: 250,
    },
    fullList: {
      backgroundColor: (darkMode === 'true') ? styles.colorDarkMain : styles.colorPinkMain,
      color: (darkMode === 'true') ? styles.colorDarkText : styles.colorWhiteText,
      width: 'auto'
    },
    buttonNavigationActionRoot: {
      color: (darkMode === 'true') ? styles.colorDarkText : styles.colorWhiteText
    },
    buttonNavigationActionSelected: {
      backgroundColor: (darkMode === 'true') ? styles.colorHoverDarkMain : styles.colorHoverPinkMain,
      color: (darkMode === 'true') ? styles.colorHoverDarkText+'!important' : styles.colorHoverWhiteText+'!important'
    },
    menuMobileItemIcon: {
      paddingRight: '5px',
      paddingBottom: '2px'
    },
    popover: {
      pointerEvents: 'none',
    },
    popoverPaper: {
      backgroundColor: (darkMode === 'true') ? styles.colorHoverDarkMain : styles.colorHoverPinkMain,
      color: (darkMode === 'true') ? styles.colorDarkText+'!important' : styles.colorWhiteText+'!important',
      padding: theme.spacing(1),
    },
  }),
);

export default useStyles;