/* eslint-disable max-len */
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { styles } from '../../config/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      flexGrow: 1,
      backgroundColor: styles.colorPinkMain,
      maxHeight: '58px',
      boxShadow: 'none'
    },
    toolbar: {
      justifyContent: 'space-between'
    },
    logo: {
      width: '30px',
      height: '30px'
    },
    home: {
      display: 'flex',
      textDecoration: 'none',
      [theme.breakpoints.only('xs')]: { paddingLeft: '2%' },
    },
    title: {
      color: '#fff',
      paddingLeft: '6px',
    },
    buttonPinkHeader: {
      marginRight: '2%',
      width: '100px',
      borderRadius: '2rem',
      height: '35px',
      fontSize: '14px',
      color: '#fff',
      border: '1px solid #fff',
      backgroundColor: '#ff356a00',
      boxShadow: 'none',
      '&:hover': {
        color: '#fff',
        border: '1px solid #fff',
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0 0)',
        backgroundColor: 'unset',
        boxShadow: 'none'
      },
      '&:active': {
        color: '#fff',
        border: '1px solid #fff',
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.2) 0 0)',
        backgroundColor: 'unset',
        boxShadow: 'none'
      },
    },
    iconButtonExpand: {
      bottom: '10px'
    },
    expandIconWrapper: {
      display: 'flex',
      justifyContent: 'center',
      maxHeight: '40px'
    },
    moreInfoSpan: {
      fontSize: '14px',
      color: styles.colorPinkMain,
      marginRight: '22px',
      marginLeft: '-20px'
    },
    expandIcon: {
      fontSize: '2rem',
      paddingRight: '10px',
      marginTop: '5px',
      color: styles.colorPinkMain
    },
    infoMobileTitle: {
      color: '#fff',
      fontWeight: 600,
      textAlign: 'center'
    },
    infoMobileDescription: {
      color: '#fff',
      fontSize: '16px',
      textAlign: 'center'
    },
    buttonPink: {
      width: '200px',
      marginTop: '1.75rem',
      borderRadius: '2rem',
      height: '45px',
      paddingTop: '6px',
      color: '#fff',
      border: '1px solid #fff',
      backgroundColor: '#ff356a00',
      boxShadow: 'none',
      '&:hover': {
        color: '#fff',
        border: '1px solid #fff',
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0 0)',
        backgroundColor: 'unset',
        boxShadow: 'none'
      },
      '&:active': {
        color: '#fff',
        border: '1px solid #fff',
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.2) 0 0)',
        backgroundColor: 'unset',
        boxShadow: 'none'
      },
    },
    infoContent: {
      paddingBottom: '0.4rem !important',
      flex: '0 0 auto',
      width: '100%',
      backgroundColor: '#ff356a'
    }
  }),
);

export default useStyles;