/* eslint-disable max-len */
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { styles } from '../../config/styles';

const useStyles = (errorPassword: string = '', loading: boolean = false) => makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      width: '5rem',
      marginBottom: '0.5rem'
    },
    title: {
      color: '#fff',
      fontSize: '2.75rem',
      textAlign: 'center',
      fontWeight: 500,
      fontFamily: 'Ubuntu, sans-serif'
    },
    descriptionLeft: {
      color: '#fff',
      fontSize: '1.15rem',
      textAlign: 'center',
      fontWeight: 400,
      marginTop: '1.25rem'
    },
    colDescription: {
      transition: '0.9s',
      background: 'linear-gradient(135deg, '+styles.colorPinkMain+' 0%, '+styles.colorPurpleMain+' 100%)',
      height: '100%',
      width: '100%',
      paddingRight: '15px',
      paddingLeft: '15px'
    },
    colDescriptionClose: {
      flex: '0 0 41.666667%',
      maxWidth: '41.666667%',
      alignItems: 'center',
      display: 'flex',
      position: 'relative',
    },
    colDescriptionOpen: {
      flex: '0 0 100%',
      maxWidth: '100%',
    },
    buttonPink: {
      width: '200px',
      marginTop: '1.75rem',
      borderRadius: '2rem',
      height: '45px',
      paddingTop: '6px',
      color: '#fff',
      border: '1px solid #fff',
      backgroundColor: '#ff356a00',
      boxShadow: 'none',
      '&:hover': {
        color: '#fff',
        border: '1px solid #fff',
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0 0)',
        backgroundColor: 'unset',
        boxShadow: 'none'
      },
      '&:active': {
        color: '#fff',
        border: '1px solid #fff',
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.2) 0 0)',
        backgroundColor: 'unset',
        boxShadow: 'none'
      },
    },
    home: {
      color: styles.colorWhiteText,
      '&:hover': {
        color: styles.colorWhiteText
      },
      display: 'flex',
      textDecoration: 'none',
      marginTop: '0.75rem'
    },
    homeHeaderOpen: {
      justifyContent: 'space-between !important',
      display: 'flex !important',
      [theme.breakpoints.only('lg')]: { paddingLeft: '60px', paddingRight: '60px' },
      [theme.breakpoints.only('xl')]: { paddingLeft: '160px', paddingRight: '160px' },
    },
    homeHeaderClose: {
      display: 'none !important'
    },
    titleHeader: {
      display: 'block',
      marginLeft: '8px',
      fontWeight: 600,
      fontSize: '1.35rem',
      fontFamily: 'Ubuntu, sans-serif'
    },
    logoHeader: {
      width: '36px',
      height: '36px'
    },
    buttonPinkHeader: {
      marginLeft: '10px',
      width: '100px',
      borderRadius: '2rem',
      height: '35px',
      fontSize: '12px',
      paddingTop: '6px',
      marginTop: '1rem',
      color: '#fff',
      border: '1px solid #fff',
      backgroundColor: '#ff356a00',
      boxShadow: 'none',
      '&:hover': {
        color: '#fff',
        border: '1px solid #fff',
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0 0)',
        backgroundColor: 'unset',
        boxShadow: 'none'
      },
      '&:active': {
        color: '#fff',
        border: '1px solid #fff',
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.2) 0 0)',
        backgroundColor: 'unset',
        boxShadow: 'none'
      },
    },
    moreInfo: {
      color: '#fff',
      marginTop: '1rem',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    formTitle: {
      color: styles.colorPinkMain,
      fontSize: '2.25rem',
      fontWeight: 500,
      fontFamily: 'Ubuntu, sans-serif',
      textAlign: 'center'
    },
    buttonEnter: {
      width: '200px',
      marginTop: '0.25rem',
      borderRadius: '2rem',
      height: '45px',
      paddingTop: '6px',
      color: '#fff',
      backgroundColor: styles.colorPinkMain,
      boxShadow: 'none',
      '&:hover': {
        color: '#fff',
        backgroundColor: styles.colorHoverPinkMain,
        boxShadow: 'none'
      },
      '&:disabled': {
        backgroundColor: styles.colorHoverLightPinkMain
      }
    },
    iconButtonSocial: {
      width: '47px',
      backgroundColor: '#e8e8e8',
      marginRight: '10px',
      '&:hover': {
        backgroundColor: '#ddd'
      },
      border: '1px solid #ddd'
    },
    iconGoogle: {
      width: '20px',
      height: '20px'
    },
    iconFacebook: {
      width: '20px',
      height: '20px'
    },
    description: {
      color: '#6c757d',
      textAlign: 'center',
      marginBottom: '0.75rem!important',
      marginTop: '1rem!important',
      [theme.breakpoints.down('sm')]: { paddingBottom: '0.8rem!important' },
    },
    inputRoot: {
      borderRadius: '12px',
      color: styles.colorPinkTextfield,
      backgroundColor: styles.colorBackgroundTextfield,
      '&:hover': {
        backgroundColor: styles.colorBackgroundHoverTextfield
      },
    },
    underline: {
      '&:before': {
        border: '0px'
      },
      '&:after': {
        border: '0px'
      },
      '&:hover:before': {
        border: '0px'
      }
    },
    input: {
      color: '#444',
      padding: '14px 12px 12px',
      '&::placeholder': {
        color: '#000'
      }
    },
    errorMessage: {
      color: styles.colorPinkMain,
      height: '20px!important'
    },
    errorMessagePassword: {
      color: styles.colorPinkMain,
      height: errorPassword.length > 23 ? '40px!important' : '20px!important'
    },
    circularProgress: {
      color: '#fff',
      left: '30px',
      position: 'relative'
    },
    buttonText: {
      paddingLeft: loading ? '20px' : '0px'
    },
    forgotPassword: {
      marginTop: '0.25rem!important',
      color: '#444',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    seePasswordIcon: {
      [theme.breakpoints.down('sm')]: { bottom: '0rem!important' },
    },
    animationOutUp: {
      animation: `$fadeOutUpEffect 1000ms ${theme.transitions.easing.easeInOut}`,
    },
    animationOutDown: {
      animation: `$fadeOutDownEffect 1000ms ${theme.transitions.easing.easeInOut}`,
    },
    animationInUp: {
      animation: `$fadeInUpEffect 1000ms ${theme.transitions.easing.easeInOut}`,
    },
    animationInDown: {
      animation: `$fadeInDownEffect 1000ms ${theme.transitions.easing.easeInOut}`,
    },
    centerVerticalInfoFirst: {
      height: 'calc(100% - 20vh)',
      display: 'flex',
      alignItems: 'center'
    },
    centerVerticalInfoSecond: {
      height: 'calc(100% - 20vh)',
      display: 'flex',
      alignItems: 'stretch',
      flexWrap: 'wrap',
      alignContent: 'center',
      justifyContent: 'center'
    },
    infoTitle: {
      color: '#fff',
      fontSize: '60px',
      fontWeight: 'bold',
    },
    infoImage: {
      width: '100%',
      paddingTop: '40px'
    },
    infoDescription: {
      color: '#fff',
      fontSize: '1.25rem',
      textAlign: 'left',
      fontWeight: 400
    },
    paddingLeftInfo: {
      [theme.breakpoints.only('lg')]: { paddingLeft: '50px', },
      [theme.breakpoints.only('xl')]: { paddingLeft: '100px', },
    },
    marginLeftInfo: {
      [theme.breakpoints.only('lg')]: { marginLeft: '50px', },
      [theme.breakpoints.only('xl')]: { marginLeft: '100px', },
    },
    paddingRightInfo: {
      [theme.breakpoints.only('lg')]: { paddingRight: '50px', },
      [theme.breakpoints.only('xl')]: { paddingRight: '100px', },
    },
    marginRightInfo: {
      [theme.breakpoints.only('lg')]: { marginRight: '50px', },
      [theme.breakpoints.only('xl')]: { marginRight: '100px', },
    },
    buttonInfoExpandMore: {
      width: '220px',
      borderRadius: '2rem',
      height: '45px',
      fontSize: '12px',
      paddingTop: '6px',
      marginTop: '1.8rem',
      color: '#fff',
      border: '1px solid #fff',
      backgroundColor: '#ff356a00',
      boxShadow: 'none',
      '&:hover': {
        color: '#fff',
        border: '1px solid #fff',
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0 0)',
        backgroundColor: 'unset',
        boxShadow: 'none'
      },
      '&:active': {
        color: '#fff',
        border: '1px solid #fff',
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.2) 0 0)',
        backgroundColor: 'unset',
        boxShadow: 'none'
      },
    },
    expandMoreIcon: {
      fontSize: '30px!important'
    },
    '@keyframes fadeInUpEffect': {
      '0%': {
        opacity: 0,
        transform: 'translateY(-30px)'
      },
      '100%': {
        opacity: 1,
        transform: 'translateY(0)'
      }
    },
    '@keyframes fadeInDownEffect': {
      '0%': {
        opacity: 0,
        transform: 'translateY(30px)'
      },
      '100%': {
        opacity: 1,
        transform: 'translateY(0)'
      }
    },
    '@keyframes fadeOutDownEffect': {
      '0%': {
        opacity: 1,
        transform: 'translateY(0)'
      },
      '100%': {
        opacity: 0,
        transform: 'translateY(-30px)'
      }
    },
    '@keyframes fadeOutUpEffect': {
      '0%': {
        opacity: 1,
        transform: 'translateY(0)'
      },
      '100%': {
        opacity: 0,
        transform: 'translateY(30px)'
      }
    },
    infoSecondTitle: {
      color: '#fff',
      fontSize: '34px',
      textAlign: 'center',
      paddingTop: '10px',
      paddingBottom: '6px'
    },
    card: {
      [theme.breakpoints.only('lg')]: { minWidth: 275 },
      [theme.breakpoints.only('md')]: { minWidth: 200 },
    },
    cardComponent: {
      textAlign: 'center',
      justifyContent: 'center'
    },
    cardTitle: {
      marginBottom: '16px',
      color: styles.colorSelectedPinkMain,
      [theme.breakpoints.only('lg')]: { fontSize: '1.2rem' },
    },
    cardImage: {
      marginTop: '16px',
      marginBottom: '16px'
    },
    cardDescription: {
      fontSize: '12px',
      color: styles.colorDescriptionText,
      paddingLeft: '10px',
      paddingRight: '10px',
      [theme.breakpoints.only('lg')]: {
        paddingLeft: '5px',
        paddingRight: '5px'
      },
    }
  }),
);

export default useStyles;