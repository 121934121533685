import React from 'react'

const DashboardPage = () => {
  return (
    <div>
      Dashboard
    </div>
  )
};

export default DashboardPage;
