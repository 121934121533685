/* eslint-disable max-len */
import React from 'react';
import { isTablet, isMobileOnly, withOrientationChange } from 'react-device-detect';
import HomeDesktopPage from './HomeDesktopPage';
import HomeMobilePage from './HomeMobilePage';

const HomePage = (orientation: any) => {
  const { isPortrait } = orientation;

  return (
    <React.Fragment>
      {isMobileOnly || (isTablet && isPortrait) ? (
        <HomeMobilePage />
      ): (
        <HomeDesktopPage />
      )
      }
    </React.Fragment>
  )
};

export default withOrientationChange(HomePage);
