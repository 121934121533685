/* eslint-disable max-len */
import React, { FC, useState, MouseEvent, ChangeEvent } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import useStyles from './UseStyles';
import validateForgotPassword from '../../utils/validations/validateForgotPassword';
import { useDispatch, useSelector } from 'react-redux';
import { fetchForgotPassword, selectForgotPassword, initClean } from '../../redux/User/ForgotPasswordSlice';
import { isMobileOnly } from 'react-device-detect';

interface Props {
  openInfo?: boolean
}

const ForgotPassword: FC<Props> = ({ openInfo }: Props) => {
  const [state, setState] = useState({
    errors: {
      email: ''
    },
    email: ''
  });

  const dispatch = useDispatch();
  const { loading, error } = useSelector(selectForgotPassword);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleForgotPassword = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(initClean());
    // eslint-disable-next-line no-unused-vars
    const { errors, ...noErrors } = state;
    const results = validateForgotPassword(noErrors);
    setState((prevState) => ({ ...prevState, errors: results }));
    if (Object.entries(results).length === 0) {
      dispatch(fetchForgotPassword(state.email));
    }
  };

  const classes = useStyles()();

  return (
    <div
      className={isMobileOnly ? 'col-9 align-items-center px-0 my-5' : 'col-7 align-items-center justify-content-around'}
      style={{
        display: openInfo ? 'none' : 'flex',
        animation: 'fadeIn 0.3s'
      }}
    >
      <div className="row justify-content-center">
        <div className="col-lg-6 col-xs-12">
          <div className="row justify-content-center">
            <Typography className={classes.formTitle} variant="h2">
              Forgot password?
            </Typography>
            <Typography className={classes.description} variant="body2">
              {'Enter the email address associated with your account and we\'ll send you a link to reset your password.'}
            </Typography>
            <div className="row mt-3 justify-content-center">
              <form>
                <div className="row">
                  <TextField
                    id="email"
                    name="email"
                    InputProps={{classes: {root: classes.inputRoot, underline: classes.underline, input: classes.input}}}
                    variant="filled"
                    placeholder="Email"
                    onChange={handleChange}
                  />
                  <Typography className={classes.errorMessage} variant="body2">
                    {state.errors.email || error.message}
                  </Typography>
                </div>
                <div className="row mt-1 justify-content-center">
                  <Button
                    className={classes.buttonEnter}
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={(e) => handleForgotPassword(e)}
                  >
                    <div className={classes.buttonText}>Send email</div>
                    {loading &&
                      <CircularProgress className={classes.circularProgress} size={20} />
                    }
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ForgotPassword;