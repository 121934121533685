/* eslint-disable max-len */
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { styles } from '../../config/styles';

const useStyles = (darkMode: string) => makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '6px',
      color: (darkMode === 'true') ? styles.colorDarkPinkTextfield : styles.colorPinkTextfield,
      backgroundColor: (darkMode === 'true') ? styles.colorDarkBackgroundTextfield : styles.colorBackgroundTextfield
    },
    underline: {
      '&:before': {
        border: '0px'
      },
      '&:after': {
        border: '0px'
      },
      '&:hover:before': {
        border: '0px'
      }
    },
    input: {
      '&::placeholder': {
        color: (darkMode === 'true') ? '#fff' : '#000'
      }
    },
    errorMessage: {
      color: (darkMode === 'true') ? styles.colorDarkErrorMessage : styles.colorErrorMessage,
      height: '20px!important',
    },
    inputLabel: {
      color: (darkMode === 'true') ? styles.colorDarkTitleText : styles.colorTitleText
    },
    button: {
      margin: theme.spacing(0),
      width: '100%',
      backgroundColor: (darkMode === 'true') ? styles.colorPinkMain : styles.colorPinkMain,
      boxShadow: 'none',
      height: '45px',
      '&:hover': {
        backgroundColor: (darkMode === 'true') ? styles.colorHoverPinkMain : styles.colorHoverPinkMain,
        boxShadow: 'none',
      },
      '&:focus': {
        backgroundColor: (darkMode === 'true') ? styles.colorSelectedPinkMain : styles.colorSelectedPinkMain,
        boxShadow: 'none',
      },
    },
    buttonSmallFont: {
      margin: theme.spacing(0),
      width: '100%',
      backgroundColor: (darkMode === 'true') ? styles.colorPinkMain : styles.colorPinkMain,
      boxShadow: 'none',
      height: '45px',
      '&:hover': {
        backgroundColor: (darkMode === 'true') ? styles.colorHoverPinkMain : styles.colorHoverPinkMain,
        boxShadow: 'none',
      },
      '&:focus': {
        backgroundColor: (darkMode === 'true') ? styles.colorSelectedPinkMain : styles.colorSelectedPinkMain,
        boxShadow: 'none',
      },
      fontSize: '10px',
      paddingLeft: '4px',
      paddingRight: '4px',
      fontWeight: 700
    },
    checkboxNotificationLabel: {
      marginTop: '-6px'
    },
    buttonCreate: {
      width: '50%',
      backgroundColor: (darkMode === 'true') ? styles.colorPinkMain : styles.colorPinkMain,
      boxShadow: 'none',
      height: '45px',
      '&:hover': {
        backgroundColor: (darkMode === 'true') ? styles.colorHoverPinkMain : styles.colorHoverPinkMain,
        boxShadow: 'none',
      },
      '&:focus': {
        backgroundColor: (darkMode === 'true') ? styles.colorSelectedPinkMain : styles.colorSelectedPinkMain,
        boxShadow: 'none',
      },
      borderRadius: '20px'
    }
  }),
);

export default useStyles;