/* eslint-disable max-len */
import { isEmail, isName, passwordsMatch, isPassword, isNotOnlyWhitespace } from '../validations';

interface values {
  name?: string;
  email?: string;
  password?: string;
  repeatPassword?: string;
}

const validateSignUp = (values: values) => {
  const errors: any = {};
  if (values.name && (!isName(values.name) || !isNotOnlyWhitespace(values.name))) {
    errors.name = 'Please enter a valid name';
  }
  if (!values.name) {
    errors.name = 'Please enter a name';
  }
  if (values.password && values.repeatPassword && !passwordsMatch(values.password, values.repeatPassword)) {
    errors.repeatPassword = 'Passwords must match';
  }
  if (values.password && !values.repeatPassword) {
    errors.repeatPassword = 'Passwords must match';
  }
  if (values.password && !isPassword(values.password)) {
    errors.password = 'Password must have at least 8 characters, 1 uppercase letter, 1 number and 1 special character';
  }
  if (!values.password) {
    errors.password = 'Please enter a password';
  }
  if (values.email && !isEmail(values.email)) {
    errors.email = 'Please enter a valid email';
  }
  if (!values.email) {
    errors.email = 'Please enter an email';
  }
  return errors;
};

export default validateSignUp;