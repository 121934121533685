import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { isMobileOnly } from 'react-device-detect';

interface Props {
  children: string
}

const Divider: FC<Props> = ({ children }: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        display: 'flex',
        alignItems: 'center',
        marginTop: isMobileOnly ? '0rem' : '0.5rem'
      },
      border: {
        borderBottom: '1px solid #bbc0c5',
        width: '100%'
      },
      content: {
        fontSize: '0.875rem',
        color: '#6c757d',
        padding: '0 10px 0 10px'
      },
    }),
  );
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.border} />
      <span className={classes.content}>
        {children}
      </span>
      <div className={classes.border} />
    </div>
  );
};

export default Divider;
