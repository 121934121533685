/* eslint-disable max-len */
import React, { FC, useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Logo from '../../images/logo-white-svg.svg';
import useStyles from './UseStyles';
import InfoFirst from './InfoFirst';
import InfoSecond from './InfoSecond';
import InfoThird from './InfoThird';
import { config } from '../../config/index';

interface Props {
  setShowDescription: (value: boolean) => void,
  showDescription: boolean,
  infoType: string,
  openInfo: boolean,
  setOpenInfo: (value: boolean) => void,
  setSign: (value: string) => void
}

const Info: FC<Props> = ({ setShowDescription, showDescription, infoType, openInfo, setOpenInfo, setSign }: Props) => {
  const [infoPageNumber, setInfoPageNumber] = useState(1);

  useEffect(() => {
    if (openInfo) {
      setTimeout(() => {
        setShowDescription(false);
      }, 900);
    }
  }, [openInfo]);

  const handleCloseInfo = (option: string) => {
    setSign(option);
    setOpenInfo(!openInfo);
    setTimeout(() => {
      setShowDescription(true);
    }, 900);
    setInfoPageNumber(1);
  };

  const classes = useStyles()();

  return (
    <React.Fragment>
      <div className={openInfo ? classes.homeHeaderOpen : classes.homeHeaderClose} >
        <div className={classes.home} >
          <img src={Logo} className={classes.logoHeader} alt="logo" />
          <Typography className={classes.titleHeader} variant="h6" noWrap>
            { config.appName }
          </Typography>
        </div>
        {infoType === 'moreInfo' &&
          <div>
            <Button
              className={classes.buttonPinkHeader}
              variant="contained"
              color="primary"
              onClick={() => handleCloseInfo('signIn')}
            >
              Sign in
            </Button>
            <Button
              className={classes.buttonPinkHeader}
              variant="contained"
              color="primary"
              onClick={() => handleCloseInfo('signUp')}
            >
              Sign up
            </Button>
          </div>
        }
      </div>
      {!showDescription && openInfo && infoPageNumber === 1 &&
        <InfoFirst setInfoPageNumber={setInfoPageNumber} />
      }
      {!showDescription && openInfo && infoPageNumber === 2 &&
        <InfoSecond setInfoPageNumber={setInfoPageNumber} />
      }
      {!showDescription && openInfo && infoPageNumber === 3 &&
        <InfoThird setInfoPageNumber={setInfoPageNumber} />
      }
    </React.Fragment>
  )
};

export default Info;
