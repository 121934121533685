import { createSlice } from '@reduxjs/toolkit';
import { ThunkDispatch as Dispatch } from 'redux-thunk';

const initialState = {
  loading: false,
  auth: false,
  error: {},
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    init: (state) => {
      state.loading = true
    },
    authorized: (state) => {
      state.loading = false
      state.auth = true
      state.error = {}
    },
    unauthorized: (state) => {
      state.loading = false
      state.auth = false
      state.error = {}
    },
    failure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
});

export const { init, authorized, unauthorized, failure } = AuthSlice.actions;
export const selectAuth = (state: any) => state.auth;
export default AuthSlice.reducer;

export function fetchAuth() {
  return async (dispatch: Dispatch<any, any, any>) => {
    try {
      dispatch(init());
      // eslint-disable-next-line no-unused-vars
      await stall();
      dispatch(authorized());
    } catch (error) {
      dispatch(failure({ message: 'Error en API' }));
    }
  }
};

async function stall(stallTime = 1000) {
  await new Promise((resolve) => setTimeout(resolve, stallTime));
};