import React, { useState } from 'react';
import Description from '../../components/Home/Description';
import SignIn from '../../components/Home/SignIn';
import SignUp from '../../components/Home/SignUp';
import ForgotPassword from '../../components/Home/ForgotPassword';

const HomeDesktopPage = () => {
  const [openInfo, setOpenInfo] = useState(false);
  const [infoType, setInfoType] = useState('moreInfo');
  const [sign, setSign] = useState('signIn');

  return (
    <div className={openInfo ? 'row h-100 w-100 gx-0' : 'row h-100 w-100'}>
      <Description
        infoType={infoType}
        openInfo={openInfo}
        setOpenInfo={setOpenInfo}
        sign={sign}
        setSign={setSign}
      />
      {sign === 'signIn' &&
        <SignIn openInfo={openInfo} setSign={setSign} />
      }
      {sign === 'signUp' &&
        <SignUp
          setInfoType={setInfoType}
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
        />
      }
      {sign === 'forgotPassword' &&
        <ForgotPassword openInfo={openInfo} />
      }
    </div>
  )
}

export default HomeDesktopPage;
