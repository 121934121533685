/* eslint-disable max-len */
import React, { FC, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { isTablet } from 'react-device-detect';
import MoreInfo3 from '../../images/more-info-3.png';
import useStyles from './UseStyles';

interface Props {
  setInfoPageNumber: (value: number) => void
}

const InfoThird: FC<Props> = ({ setInfoPageNumber }: Props) => {
  const [actualPage, setActualPage] = useState(true);

  const handleChangePage = () => {
    setActualPage(false);
    setTimeout(() => {
      setInfoPageNumber(1);
    }, 1000);
  };

  const classes = useStyles()();

  return (
    <div
      className={actualPage ?
        clsx(classes.centerVerticalInfoFirst, classes.animationInDown) :
        clsx(classes.centerVerticalInfoFirst, classes.animationOutDown)
      }
    >
      <div className={isTablet ? 'row mx-5 fixTabletInfoMargin' : 'row mx-5 fixDesktopInfoMargin'}>
        <div className="col-lg-6">
          <img
            className={clsx(classes.infoImage, classes.paddingLeftInfo)}
            src={MoreInfo3}
            alt="info-img"
          />
        </div>
        <div className="col-lg-6 fixCol6Center">
          <Typography
            className={clsx(classes.infoTitle, classes.marginRightInfo)}
            variant="h1"
            component="h2"
            gutterBottom
          >
            The science behind this Atomic Habits App
          </Typography>
          <Typography
            className={clsx(classes.infoDescription, classes.marginRightInfo)}
            variant="h5"
          >
            Atomic Habits was designed with a simple goal in mind: to fuel the world with the power of behavioral science. Our team of behavioral economists, psychologists, and data scientists are constantly improving Atomic Habits and finding new ways to share the benefits with more people.
          </Typography>
          <Button
            className={clsx(classes.marginRightInfo, classes.buttonInfoExpandMore)}
            variant="contained"
            color="primary"
            onClick={handleChangePage}
            startIcon={<ExpandLessIcon className={classes.expandMoreIcon} />}
          >
            Back to top
          </Button>
        </div>
      </div>
    </div>
  )
};

export default InfoThird;